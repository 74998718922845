import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from '../components/layout';

const Container = styled.div`
	max-width: 1000px;
	display: flex;
	justify-content: center;
	margin: 0 auto;
`
const IntroSection = styled.section`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`
const ProjectScope = styled.div``
const Title = styled.h1``
const WorkDone = styled.p``
const Description = styled.p``

class WorkTemplate extends Component {
	state = {}
	render() {
		return (
			<Layout>
				<Container>
					<IntroSection>
						<ProjectScope>
							<Title>TYPE DIRECTORS CLUB</Title>
							<WorkDone>BOARD</WorkDone>
						</ProjectScope>
						<Description>
							The TDC has been a big part of my design life for more than a decade. In 2016 I joined the Board of Directors, where I serve on the Membership Committee, conducting monthly interviews, running the Member Slack, and designing merch.
						</Description>
					</IntroSection>
				</Container>
			</Layout>
		);
	}
}

export default WorkTemplate;